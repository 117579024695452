<template>
  <div class="bdd-frais">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px "
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterFiliale"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedFiliale(filterMonth, filterWeek)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select w-160-px "
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorFraisBdd" class="error">
            <ul v-if="Array.isArray(getErrorFraisBdd)" class="mb-0">
              <li v-for="(e, index) in getErrorFraisBdd" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisBdd }}</div>
          </div>
        </div>
        <div
          v-if="getLoadingFraisBdd"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <b-button
            v-if="cantExport(this.$options.name)"
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-box-rapport ">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableFraisBdd table-header"
          :items="computedFraisBdd"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <!-- <template v-slot:cell(details)=""> </template> -->
          <template v-slot:cell(numero_dossier)="data">
            {{ data.item.numero_dossier }}
          </template>
          <template v-slot:cell(period_de_paie)="data">
            {{ data.item.period_de_paie }}
          </template>
          <template v-slot:cell(nom1)="data">
            {{ data.item.nom1 }}
          </template>
          <template v-slot:cell(pernom1)="data">
            {{ data.item.pernom1 }}
          </template>
          <template v-slot:cell(installer_rge)="data">
            {{ data.item.installer_rge }}
          </template>
          <template v-slot:cell(montant_total_ht)="data">
            {{ data.item.montant_total_ht }} €
          </template>
          <template v-slot:cell(montant_prime_cee)="data">
            {{ data.item.montant_prime_cee }} €
          </template>
          <template v-slot:cell(montant_a_5_ht)="data">
            {{ data.item.montant_a_5_ht }} €
          </template>
          <template v-slot:cell(montant_final)="data">
            {{ data.item.montant_final }} €
          </template>
          <template v-slot:cell(super_regie)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="super_regie"
              editableType="select"
              :optionsSelect="RegieList"
              :item="data.item"
              :value="data.item.super_regie"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(statue_operationnel)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_operationnel"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_operationnel"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(operationnel)="data">
            {{ data.item.operationnel }} €
          </template>
          <template v-slot:cell(statue_commercial)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_commercial"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_commercial"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(commercial)="data">
            {{ data.item.commercial }} €
          </template>
          <template v-slot:cell(statue_marketing)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_marketing"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_marketing"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(marketing)="data">
            {{ data.item.marketing }} €
          </template>
          <template v-slot:cell(statue_technique)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_technique"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_technique"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(technique)="data">
            {{ data.item.technique }} €
          </template>
          <template v-slot:cell(statue_rge)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_rge"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_rge"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(rge)="data"> {{ data.item.rge }} € </template>
          <template v-slot:cell(statue_CDG)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_CDG"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_CDG"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(CDG)="data"> {{ data.item.CDG }} € </template>

          <template v-slot:cell(statue_administratif_sav)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_administratif_sav"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_administratif_sav"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(administratif_sav)="data">
            {{ data.item.administratif_sav }} €
          </template>
          <template v-slot:cell(statue_dsi)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_dsi"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_dsi"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>

          <template v-slot:cell(dsi)="data"> {{ data.item.dsi }} € </template>
          <template v-slot:cell(statue_juridique)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_juridique"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_juridique"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(juridique)="data">
            {{ data.item.juridique }} €
          </template>
          <template v-slot:cell(statue_alger)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_alger"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_alger"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>

          <template v-slot:cell(alger)="data">
            {{ data.item.alger }} €
          </template>
          <template v-slot:cell(statue_comptabilite)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_comptabilite"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_comptabilite"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(comptabilite)="data">
            {{ data.item.comptabilite }} €
          </template>
          <template v-slot:cell(statue_RH)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_RH"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_RH"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(RH)="data"> {{ data.item.RH }} € </template>
          <template v-slot:cell(statue_bureau_detude)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_bureau_detude"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_bureau_detude"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(bureau_detude)="data">
            {{ data.item.bureau_detude }} €
          </template>
          <template v-slot:cell(statue_mairie)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_mairie"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_mairie"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(mairie)="data">
            {{ data.item.mairie }} €
          </template>
          <template v-slot:cell(statue_maintenance_pac)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="statue_maintenance_pac"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue_maintenance_pac"
              :updateFunction="updateStatueFraisBdd"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(maintenance_pac)="data">
            {{ data.item.maintenance_pac }} €
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalfraisBddData"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import EditableInput from '../../component/EditableInput.vue';
export default {
  name: 'Frais-BDD',
  data() {
    return {
      filterWeek: null,
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterFiliale: null,
      filterDepot: null,
      page: 1,
      perPage: 50,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        // {
        //   key: 'details',
        //   label: '',
        //   thClass: 'th-color',
        //   tdClass: 'color'
        // },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          thClass: 'th-num-fixed',
          tdClass: 'td-num-fixed'
        },

        { key: 'period_de_paie', label: 'Période de paie' },
        { key: 'nom1', label: 'Nom1' },
        { key: 'pernom1', label: 'Prenom1' },

        {
          key: 'installer_rge',
          label: 'Filiale Installateur'
        },
        { key: 'montant_total_ht', label: 'Montant Total HT' },
        { key: 'montant_prime_cee', label: 'Montant Prime CEE' },
        { key: 'montant_a_5_ht', label: 'Montant à 5€ HT' },
        { key: 'montant_final', label: 'Montant min' },
        { key: 'numero_de_depot', label: 'Numéro de dépôt' },
        { key: 'depot_stockage', label: 'Depôt stockage' },
        { key: 'depot', label: 'Depôt' },
        { key: 'super_regie', label: 'Super Régie' },
        {
          key: 'statue_operationnel',
          label: 'Statut Opérationnel'
        },
        { key: 'operationnel', label: 'Gestion Opérationnel' },
        {
          key: 'statue_commercial',
          label: 'Statut Commercial'
        },
        { key: 'commercial', label: 'Gestion Commercial ' },
        { key: 'statue_marketing', label: 'Statut Marketing' },
        { key: 'marketing', label: 'Gestion Marketing' },
        { key: 'statue_technique', label: 'Statut Technique' },
        { key: 'technique', label: 'Gestion Technique ' },
        { key: 'statue_rge', label: 'Statut RGE' },
        { key: 'rge', label: 'Gestion RGE ' },
        { key: 'statue_CDG', label: 'Statut CDG' },
        { key: 'CDG', label: 'CDG ' },
        {
          key: 'statue_administratif_sav',
          label: 'Statut SAV'
        },
        {
          key: 'administratif_sav',
          label: 'Gestion SAV  '
        },
        { key: 'statue_dsi', label: 'Statut DSI' },
        { key: 'dsi', label: 'DSI  ' },
        { key: 'statue_juridique', label: 'Statut juridique' },
        { key: 'juridique', label: 'Juridique ' },
        { key: 'statue_alger', label: 'Statut Alger' },
        { key: 'alger', label: 'Alger - Gestion ' },
        { key: 'statue_comptabilite', label: 'Statut Comptabilité' },
        { key: 'comptabilite', label: 'Comptabilité' },
        { key: 'statue_RH', label: 'Statut RH' },
        { key: 'RH', label: 'RH / Secretariat' },
        { key: 'statue_bureau_detude', label: "Statut Bureau d'étude" },
        { key: 'bureau_detude', label: "Bureau d'étude" },
        { key: 'statue_mairie', label: 'Statut Mairie' },
        { key: 'mairie', label: 'Mairie' },
        {
          key: 'statue_maintenance_pac',
          label: 'Statut  PAC'
        },
        { key: 'maintenance_pac', label: 'Maintenace PAC' }
      ],
      RegieList: [
        { value: 'EXTERNE', full_name: 'EXTERNE' },
        { value: 'INTERNE', full_name: 'INTERNE' }
      ],
      searchValue: null
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getAllDepotForYearTh',
      'getLoadingFraisBdd',
      'getFraisBddData',
      'cantValid',
      'cantUpdate',
      'cantExport',
      'getTotalfraisBddData',
      'getErrorFraisBdd',

      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots'
    ]),
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedDepot() {
      let tab = [];
      if (this.filterMonth == null) {
        tab = this.getAllDepotForYearTh.map(item => ({
          value: item.depot,
          text: item.depot
        }));
        return tab;
      } else {
        let table = this.getMonthForYearTh.filter(
          item => item.month_name == this.filterMonth
        );
        if (table.length) {
          tab = table[0].depots.map(item => ({
            value: item.depot,
            text: item.depot
          }));
          return tab;
        } else {
          return [];
        }
      }
    },
    computedFraisBdd() {
      return this.getFraisBddData;
    },
    computedFields() {
      return this.fields;
    },
    computedValidationList() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        },
        { value: 'paye', full_name: 'Facture payée' }
      ];
    }
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'getAllFraisBdd',
      'updateStatueFraisBdd',
      'exportExcelFraisBddTh'
    ]),
    handleChangeYear() {
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterWeek = null;
      this.filterFiliale = null;
      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterWeek = null;
      this.filterFiliale = null;
      this.handleFilter();
    },
    handleChangeWeek() {
      this.filterDepot = null;
      this.filterFiliale = null;
      this.handleFilter();
    },
    handleChangeFiliale() {
      this.filterDepot = null;
      this.handleFilter();
    },
    handleChangeDepot() {
      this.handleFilter();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      sessionStorage.setItem('page-bdd', this.page);
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    handleFilter() {
      this.setLocalStorageBdd();
      this.getAllFraisBdd({
        page: this.page,
        per_page: this.perPage,
        year: this.filteryear,
        month: this.filterMonth,
        depot: this.filterDepot,
        search: this.searchValue,
        filiale: this.filterFiliale,
        week: this.filterWeek
      });
    },
    setLocalStorageBdd() {
      localStorage.setItem(
        'bdd',
        JSON.stringify({
          filterMonth: this.filterMonth,
          filterWeek: this.filterWeek,
          filterFiliale: this.filterFiliale,
          filterDepot: this.filterDepot,
          filteryear: this.filteryear
        })
      );
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return 'ligneEnCourDeValidation';
    },
    async exportFiles() {
      await this.exportExcelFraisBddTh({
        year: this.filteryear,
        depot: this.filterDepot,
        month: this.filterMonth
      });
    }
  },
  components: {
    EditableInput,
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  async mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    if (localStorage.getItem('bdd')) {
      this.filteryear = JSON.parse(localStorage.getItem('bdd')).filteryear;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    if (localStorage.getItem('bdd')) {
      // this.filteryear = JSON.parse(localStorage.getItem('bdd')).filteryear;
      this.filterMonth = JSON.parse(localStorage.getItem('bdd')).filterMonth;
      this.filterDepot = JSON.parse(localStorage.getItem('bdd')).filterDepot;
      this.filterWeek = JSON.parse(localStorage.getItem('bdd')).filterWeek;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('bdd')
      ).filterFiliale;
    } else {
      this.filteryear = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageBdd();
    }
    if (sessionStorage.getItem('page-bdd')) {
      this.page = sessionStorage.getItem('page-bdd');
    } else {
      sessionStorage.setItem('page-bdd', this.page);
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.bdd-frais {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableFraisBdd {
      max-height: calc(100vh - 210px) !important;
      height: calc(100vh - 228px) !important;
      margin-bottom: 0px;
    }
  }
}
</style>
